import { render, staticRenderFns } from "./Dossiers.vue?vue&type=template&id=d102cb68&scoped=true"
import script from "./Dossiers.vue?vue&type=script&lang=js"
export * from "./Dossiers.vue?vue&type=script&lang=js"
import style0 from "./Dossiers.vue?vue&type=style&index=0&id=d102cb68&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d102cb68",
  null
  
)

export default component.exports